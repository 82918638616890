window.animatebanner = function ()
{

		// Volgorde
		// 3,4,5,1,2,6

		//---------------------------------------------------- Slide 3 IN ------------------------------- //
		resettime_hdx();

		var photo = document.getElementById("banner3-1");
		var tl3_1 = new TimelineLite();
		tl3_1.fromTo(photo, 1, {opacity: '0'}, {opacity: '1', delay:0}).to(photo, 1, {opacity: '0', delay:4}).call( loadapg );


		var photo = document.getElementById("banner3-2");
		var tl3_2 = new TimelineLite();
		tl3_2.fromTo(photo, 1, {opacity: '0'}, {opacity: '1', delay:1}).to(photo, 1, {opacity: '0', delay:2});




		function resettime_hdx()
		{
			$("#hdx-anim").trigger('play');
		}


		//---------------------------------------------------- Slide 4 IN ------------------------------- //
		var photo = document.getElementById("banner4-1");
		var tl4_1 = new TimelineLite();
		tl4_1.fromTo(photo, 3, {opacity: '0'}, {opacity: '1', delay:7}).to(photo, 1, {opacity: '0', delay:4}).call( resettime_wml );
		

		function loadapg()
		{
			var url = 'https://www.guidobackerra.nl/apg/index.html';
			$("#banner4-1-iframe").attr('src', url); 
		}


		//---------------------------------------------------- Slide 5 IN ------------------------------- //
		var photo = document.getElementById("banner5-1");
		var tl5_1 = new TimelineLite();
		tl5_1.fromTo(photo, 1, {opacity: '0'}, {opacity: '1', delay:15}).to(photo, 1, {opacity: '0', delay:7}).call( resettime_neuhof );
		
		var photo = document.getElementById("banner5-2");
		var tl5_2 = new TimelineLite();
		tl5_2.fromTo(photo, 1, {opacity: '0'}, {opacity: '1', delay:15}).to(photo, 1, {opacity: '0', delay:2});



		function resettime_wml()
		{
			$("#wml-anim").trigger('play');
		}




		//---------------------------------------------------- Slide 1 IN ------------------------------- //
		// var photo = document.getElementById("banner1-1");
		// var tl1_1 = new TimelineLite();
		// tl1_1.fromTo(photo, 2.5, {right: '200%', bottom: '10%'}, {right: '5%', ease:   Elastic. easeOut.config( 0.5, 0.3), delay:22}).to(photo, 2.5, {right: '200%', bottom: '10%',  delay:3});

		// var photo = document.getElementById("banner1-2");
		// var tl1_2 = new TimelineLite();
		// tl1_2.fromTo(photo, 2.5, {right: '200%', bottom: '10%'}, {right: '15%', ease:   Elastic. easeOut.config( 0.4, 0.3), delay:23}).to(photo, 2.5, {right: '200%', bottom: '10%', delay:2});
		
		// var photo = document.getElementById("banner1-3");
		// var tl1_3 = new TimelineLite();
		// tl1_3.fromTo(photo, 1, {opacity: '0'}, {opacity: '1', delay:25}).to(photo, 1, {opacity: '0', delay:2}).call( resettime_neuhof );



		//---------------------------------------------------- Slide 2 IN ------------------------------- //
		var photo = document.getElementById("banner2-1");
		var tl2_1 = new TimelineLite();
		tl2_1.fromTo(photo, 1, {opacity: '0'}, {opacity: '1', delay:24}).to(photo, 1, {opacity: '0', delay:3}).call( resettime_lambweston );


		var photo = document.getElementById("neuhof-anim");
		var tl2_2 = new TimelineLite();
		tl2_2.fromTo(photo, 4, {width: '50%', left: '25%'}, {width: '60%', left: '20%', delay:8});


		function resettime_neuhof()
		{
			$("#neuhof-anim").trigger('play');
		}
		

		//---------------------------------------------------- Slide 6 IN ------------------------------- //
		var photo = document.getElementById("banner6-1");
		var tl6_1 = new TimelineLite();
		tl6_1.fromTo(photo, 1, {opacity: '0'}, {opacity: '1', delay:30}).to(photo, 1, {opacity: '0', delay:3}).call( resettimeline );
			
		var photo = document.getElementById("banner6-2");
		var tl6_2 = new TimelineLite();
		tl6_2.fromTo(photo, 1, {opacity: '0'}, {opacity: '1', delay:31}).to(photo, 1, {opacity: '0', delay:2});


		function resettime_lambweston()
		{
			$("#lambweston-anim").trigger('play');
		}


		function resettimeline()
		{
			resettime_hdx();
			// tl1_1.restart(true, false);
			// tl1_2.restart(true, false);
			// tl1_3.restart(true, false);

			tl2_1.restart(true, false);
			tl2_2.restart(true, false);

			tl3_1.restart(true, false);
			tl3_2.restart(true, false);

			tl4_1.restart(true, false);

			tl5_1.restart(true, false);
			tl5_2.restart(true, false);

			tl6_1.restart(true, false);
			tl6_2.restart(true, false);

		}
	
}